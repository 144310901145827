<template lang="html">
    <Form @submit="handleSubmit" v-slot="{ errors }" ref="wizardForm">
        <h3 class="title" v-if="selectedForm.display_question_headline == 1">
            {{selectedForm.question_headline}}
        </h3>
        <div class="edit_section" v-if="questionStep <= questions.length" :style="`--var-ip-bg-color: ${activeForm.ip_bgcolor ? activeForm.ip_bgcolor : '#eaf7fc'};`">
            <div class="section_item p-0">
                <div class="faq_list mt-2">
                    <div v-for="(question, q) in questions" :key="q">
                        <div class="quest_wpr" v-if="questionStep == ++q">
                            <h5>
                                <label class="q_index" v-if="activeForm.show_question_number">{{q}}</label>
                                <div v-if="question.input_type_id == 7">
                                    <div class="quest_headline pt-1" v-html="question.headline"></div>
                                    <p class="agreement" v-html="question.question"></p>
                                    <label :for="`agreement-${question.id}`" class="checkbox agree_box">
                                        <input type="checkbox" :id="`agreement-${question.id}`" hidden>
                                        <span class="mr-1"><i class="fas fa-check"></i></span>
                                        <p class="info m-0">I Agree.</p>
                                    </label>
                                    <ErrorMessage :name="'answer['+ question.id + '][]'" class="text-danger">
                                        <span role="alert" class="text-danger field-error">This field is required</span>
                                    </ErrorMessage>
                                </div>
                                <div v-else>
                                  <div class="redactor-styles redactor-in p-0" v-html="question.question"></div>
                                  <div class="sub_text" v-if="question.sub_text">{{ question.sub_text }}</div>
                                </div>
                            </h5>
                            <div v-if="question.input_type_id == 1 || question.input_type_id == 4">
                                <div class="question-options">
                                    <template v-for="(option, o) in question.options" :key="o">
                                        <div class="question-oneans">
                                            <Field autocomplete="off" :name="`option-${question.id}`" v-model="form.answer[question.id]"  :rules="{'required' : question.answer_required_yn == 1}">
                                                <label :for="`check-${question.id}-${option.id}`" class="radio_opt">
                                                    <input type="radio" :id="`check-${question.id}-${option.id}`" label="question" :name="`option-${question.id}`" :value="option.id" v-model="form.answer[question.id]" @change="handleNextStep()" hidden>
                                                    <div class="radio_box" :class="{'active' : form.answer[question.id] == option.id}" :style="`border:1px solid ${selectedForm.accent_color ? selectedForm.accent_color : '#2c3e50'}`">
                                                        <span class="dot" :style="`background:${selectedForm.accent_color ? selectedForm.accent_color : '#2c3e50'}`"></span>
                                                    </div>
                                                    <p>{{ option.option }}</p>
                                                </label>
                                            </Field>
                                        </div>
                                    </template>
                                </div>
                                <ErrorMessage :name="`option-${question.id}`" class="text-danger"  v-if="showError">
                                    <span role="alert" class="text-danger">This field is required</span>
                                </ErrorMessage>
                            </div>
                            <div v-else-if="question.input_type_id == 2">
                                <div class="question-options">
                                    <template v-for="(option, o) in question.options" :key="o">
                                        <label :for="`check-${question.id}-${option.id}`" class="checkbox">
                                            <Field autocomplete="off" :name="`option-${question.id}`" v-model="form.answer[question.id]"  label="question" :rules="{'required' : question.answer_required_yn == 1}">
                                                <input type="checkbox" :id="`check-${question.id}-${option.id}`" :name="`option-${question.id}`" :value="option.id" v-model="form.answer[question.id][option.id]" hidden>
                                                <span :style="`border:1px solid ${selectedForm.accent_color ? selectedForm.accent_color : '#2c3e50'}`"><i class="fas fa-check" :style="`color:${selectedForm.accent_color ? selectedForm.accent_color : '#2c3e50'}`"></i></span>
                                                <p>{{ option.option }}</p>
                                            </Field>
                                        </label>
                                    </template>
                                </div>
                                <ErrorMessage :name="`option-${question.id}`" class="text-danger"  v-if="showError">
                                    <span role="alert" class="text-danger">This field is required</span>
                                </ErrorMessage>
                            </div>
                            <div class="setting_wpr mt-3"  v-else-if="question.input_type_id == 3">
                                <div class="form_grp py-2">
                                    <div class="group_item">
                                        <div class="field_wpr mb-3"  :class="{ 'has-error': errors[`answer-${question.id}`]  && showError}">
                                            <Field autocomplete="off" type="text" :name="`answer-${question.id}`" label="question" v-model="form.answer[question.id]" :rules="{'required' : question.answer_required_yn == 1}" :class="{ 'has-error': errors[`answer-${question.id}`]  && showError}">
                                                <textarea cols="30" rows="10" v-model="form.answer[question.id]" placeholder="Content goes here.."></textarea>
                                            </Field>
                                        </div>
                                        <ErrorMessage :name="`answer-${question.id}`" class="text-danger"  v-if="showError">
                                            <span role="alert" class="text-danger">This field is required</span>
                                        </ErrorMessage>
                                    </div>
                                </div>
                            </div>
                            <div class="setting_wpr mt-3"  v-else-if="question.input_type_id == 5">
                                <div class="form_grp py-2 mb-2">
                                    <div class="group_item">
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`answer-${question.id}`] }">
                                            <Field autocomplete="off" type="text" :name="`answer-${question.id}`" label="question" v-model="form.answer[question.id]" :rules="{'required' : question.answer_required_yn == 1}" :class="{ 'has-error': errors[`answer-${question.id}`] && showError }" />
                                        </div>
                                        <ErrorMessage :name="`answer-${question.id}`" class="text-danger"  v-if="showError">
                                            <span role="alert" class="text-danger">This field is required</span>
                                        </ErrorMessage>
                                    </div>
                                </div>
                            </div>
                            <div class="setting_wpr mt-2"  v-else-if="question.input_type_id == 6">
                                <upload-answer v-model="form.answer[question.id]"></upload-answer>

                                <Field  type="hidden" :name="`answer-${question.id}`" label="question" v-model="form.answer[question.id]"  :rules="{'required' : question.answer_required_yn == 1}" :class="{ 'has-error': errors[`answer-${question.id}`] }" />
                                <ErrorMessage :name="`answer-${question.id}`" class="text-danger">
                                    <span role="alert" class="text-danger">This field is required</span>
                                </ErrorMessage>

                                <!-- <div class="form_grp py-2">
                                    <div class="group_item">
                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`answer-${question.id}`] }">
                                            <Field autocomplete="off" type="file" :name="`answer-${question.id}`" label="question" v-model="form.answer[question.id]" :rules="{'required' : question.answer_required_yn == 1}" :class="{ 'has-error': errors[`answer-${question.id}`] && showError }" />
                                        </div>
                                        <ErrorMessage :name="`answer-${question.id}`" class="text-danger"  v-if="showError">
                                            <span role="alert" class="text-danger">This field is required</span>
                                        </ErrorMessage>
                                    </div>
                                </div> -->
                            </div>
                            <div class="setting_wpr py-2 m-0"  v-else-if="question.input_type_id == 7">
                                <Field autocomplete="off" type="hidden" :name="`answer-${question.id}`" />
                            </div>
                            <div class="initialed_fld pb-3" v-if="question.is_initial">
                                <div class="initial_display">
                                    <div class="ip_info">
                                        <i class="fas fa-info"></i>
                                        <div class="initial_lable" :style="`color: ${activeForm && activeForm.id ? activeForm.ip_textcolor : '#5a5a5a'}; background: ${activeForm && activeForm.id ? activeForm.ip_bgcolor : '#eaf7fc'}; border-color: ${activeForm && activeForm.id ? activeForm.ip_bgcolor : '#bdeafa'};`">
                                            <label>Recorded IP : <span>{{ user.ip_address }}</span></label>
                                            <label>Initialed At: <span>{{ moment().format("hh:mm A on MM/DD/YYYY") }}</span></label>
                                        </div>
                                    </div>
                                    <div class="initial_wpr">
                                        <label>Initials</label>
                                        <Field autocomplete="off" type="text" :name="`initial-${question.id}`" v-model="form.initial_text[question.id]" rules="required" :class="{ 'has-error': errors[`initial-${question.id}`] && showError }" />
                                    </div>
                                    <ErrorMessage :name="`initial-${question.id}`" class="text-danger" v-if="showError">
                                        <span role="alert" class="text-danger">The initial field is required</span>
                                    </ErrorMessage>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-4" v-if="questionStep == questions.length + 1 && signatureRequired" ref="signature-required-section">
            <div class="edit_section">
                <label for="certify" class="checkbox py-3">
                    <Field autocomplete="off" name="certify" rules="required" v-model="form.certify">
                        <input type="checkbox" id="certify" v-model="form.certify" :true-value="1" :false-value="null" hidden>
                    </Field>
                    <span class="mr-1"><i class="fas fa-check"></i></span>
                    <p class="info m-0">I certify that the information I have entered on this form is accurate. My legally binding e-signature is entered correctly and the time and date stamps are accurate. I also understand that my IP address has been recorded. I will be emailed a copy of this form for my records and I can view this document at any time by contacting Thrive Coach.</p>
                </label>
                <ErrorMessage name="certify" class="text-danger" v-if="showError">
                    <p class="text-danger">This field is required</p>
                </ErrorMessage>
                <div class="setting_wpr">
                    <div class="signature_fld">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Please Type Your Full Name Below</label>
                                <div class="field_wpr m-0" :class="{ 'has-error': errors.sign && showError }">
                                    <Field autocomplete="off" type="text" name="sign" v-model="form.sign" rules="required" />
                                    <span class="date" :style="`color: ${activeForm && activeForm.id ? activeForm.ip_textcolor : selectedForm.ip_textcolor}; background: ${activeForm && activeForm.id ? activeForm.ip_bgcolor : selectedForm.ip_bgcolor}; border-color: ${activeForm && activeForm.id ? activeForm.ip_bgcolor : selectedForm.ip_bgcolor};`">{{ moment().format("MM/DD/YYYY")}}</span>
                                </div>
                                <ErrorMessage name="sign" v-if="showError">
                                    <p class="text-danger">This field is required</p>
                                </ErrorMessage>
                            </div>
                        </div>
                    </div>
                    <label for="agree" class="checkbox justify-content-start mb-2">
                        <Field autocomplete="off" name="agree" rules="required" v-model="form.agree">
                            <input type="checkbox" id="agree" v-model="form.agree" :true-value="1" :false-value="null" hidden>
                        </Field>
                        <span class="mr-1"><i class="fas fa-check"></i></span>
                        <p class="info m-0">This is my legally binding signature.</p>
                    </label>
                    <ErrorMessage name="agree" v-if="showError">
                        <p class="text-danger">This field is required</p>
                    </ErrorMessage>
                </div>
                <div class="privacy_info" :style="`color: ${activeForm && activeForm.id ? activeForm.ip_textcolor : selectedForm.ip_textcolor}; background: ${activeForm && activeForm.id ? activeForm.ip_bgcolor : selectedForm.ip_bgcolor}; border-color: ${activeForm && activeForm.id ? activeForm.ip_bgcolor : selectedForm.ip_bgcolor};`">
                    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.53333 16.4908L15.6667 9.4657L14.6333 8.44327L8.63333 14.3799L5.36667 11.1478L4.26667 12.2691L8.53333 16.4908ZM10 25C7.11111 24.2084 4.72222 22.4989 2.83333 19.8714C0.944445 17.2438 0 14.336 0 11.1478V3.69393L10 0L20 3.69393V11.1478C20 14.336 19.0556 17.2438 17.1667 19.8714C15.2778 22.4989 12.8889 24.2084 10 25ZM10 23.4169C12.4889 22.6253 14.5278 21.0862 16.1167 18.7995C17.7056 16.5128 18.5 13.9622 18.5 11.1478V4.74934L10 1.58311L1.5 4.74934V11.1478C1.5 13.9622 2.29444 16.5128 3.88333 18.7995C5.47222 21.0862 7.51111 22.6253 10 23.4169Z" :fill="activeForm && activeForm.id ? activeForm.ip_textcolor : selectedForm.ip_textcolor"/>
                    </svg>
                    <p :style="`color: ${activeForm && activeForm.id ? activeForm.ip_textcolor : selectedForm.ip_textcolor};`">Your IP address {{ selectedForm.client_ip }} has been recorded at {{ moment().format('hh:mm A')}} on {{ moment().format('dddd')}}, {{ moment().format('MM/DD/YYYY')}}.</p>
                </div>
            </div>
        </div>
        <div class="mb-4" v-if="(questionStep == questions.length + 2 || (questionStep == questions.length + 1 && !selectedForm.is_signature)) && selectedForm.opt_in">
            <div class="setting_wpr">
                <optin-form :activeForm="activeForm" :errors="errors" />
            </div>
        </div>
        <div class="action_wpr m-0" ref="submitbutton">
            <button type="button" class="btn cancel_btn" @click="prevStep" v-if="questionStep > 1 && (questionStep != questions.length + 2 && selectedForm.is_signature && selectedForm.opt_in)">Previous</button>
            <button type="button" class="btn cancel_btn" @click="prevStep" v-if="questionStep > 1 && (questionStep != questions.length + 1 && (!selectedForm.is_signature && selectedForm.opt_in || selectedForm.is_signature && !selectedForm.opt_in))">Previous</button>
            <button type="button" class="btn cancel_btn" @click="prevStep" v-if="questionStep > 1 && (questionStep != questions.length && !selectedForm.is_signature && !selectedForm.opt_in)">Previous</button>
            <button type="button" class="btn save_btn" @click="nextStep" v-if="questionStep != questions.length + 1 && ((selectedForm.is_signature && !selectedForm.opt_in)|| (!selectedForm.is_signature && selectedForm.opt_in))" :style="`color: ${selectedForm.submit_label.btntextcolor}; background-color:  ${selectedForm.submit_label.btncolor}; border-color:  ${selectedForm.submit_label.btncolor};`">Next</button>
            <button type="button" class="btn save_btn" @click="nextStep" v-if="questionStep != questions.length + 2 && (selectedForm.is_signature && selectedForm.opt_in)" :style="`color: ${activeForm && activeForm.submit_label.btntextcolor ? activeForm.submit_label.btntextcolor : selectedForm.submit_label.btntextcolor}; background-color:  ${activeForm && activeForm.submit_label.btncolor ? activeForm.submit_label.btncolor : selectedForm.submit_label.btncolor}; border-color:  ${activeForm && activeForm.submit_label.btncolor ? activeForm.submit_label.btncolor : selectedForm.submit_label.btncolor};`">Next</button>
            <button type="button" class="btn save_btn" @click="nextStep" v-if="questionStep != questions.length + 1 && !selectedForm.is_signature && !selectedForm.opt_in" :style="`color: ${activeForm && activeForm.submit_label.btntextcolor ? activeForm.submit_label.btntextcolor : selectedForm.submit_label.btntextcolor}; background-color:  ${activeForm && activeForm.submit_label.btncolor ? activeForm.submit_label.btncolor : selectedForm.submit_label.btncolor}; border-color:  ${activeForm && activeForm.submit_label.btncolor ? activeForm.submit_label.btncolor : selectedForm.submit_label.btncolor}`">{{ questionStep == questions.length ? 'Finish' : 'Next' }}</button>
        </div>
        <div v-if="((questionStep == questions.length + 2 && selectedForm.is_signature) || (questionStep == questions.length + 1 && !selectedForm.is_signature)) && selectedForm.opt_in">
            <div class="inline-block">
                <button type="button" class="primary_btn" @click="handleSubmit" :style="`color: ${activeForm.submit_label.btntextcolor ? activeForm.submit_label.btntextcolor : selectedForm.submit_label.btntextcolor}; background-color:  ${activeForm.submit_label.btncolor ? activeForm.submit_label.btncolor : selectedForm.submit_label.btncolor};`" :class="`button-align-${selectedForm.submit_label.position}`">{{ selectedForm.submit_label.label }}</button>
            </div>
            <div class="info text-center" :class="selectedForm.submit_label.position == 'right' || selectedForm.submit_label.position == 'left' ? 'mt-4' : ''">Your submission is encrypted, secure, and confidential</div>
        </div>
    </Form>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Form, Field, ErrorMessage } from 'vee-validate'
    import { mapState } from 'vuex'

    const OptinForm = defineAsyncComponent(() => import('@/pages/form/components/OptinForm'))
    const UploadAnswer = defineAsyncComponent(() => import('@/pages/form/components/UploadAnswer'))

    import moment from 'moment'

    export default {
        data () {
            return {
                form: {
                    sign: '',
                    answer: [],
                    initial_text: [],
                    certify: 1,
                    agree: 1,
                },
                moment,
                questionStep: 1,
                wizardProgress: {
                    total: 0,
                    completed: 0,
                    percentage: 0,
                },
                signatureRequired: 0,
                showError: false,
            }
        },

        props: {
            isValidation: {
                type: Boolean,
                default: () => true,
            },
            modelValue: Object,
            activeForm: {
                type: Object,
                default: () => {}
            },
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            OptinForm,
            UploadAnswer,
        },

        watch: {
            wizardProgress: {
                handler (progress) {
                    const vm = this;

                    progress.percentage = (progress.total && progress.completed) ? (progress.completed * 100) / progress.total : 0;
                    vm.$emit('update:modelValue', progress);
                },
                deep: true,
            },

            questionStep (step) {
                const vm = this;

                if (step <= (vm.questions.length + 1)) {
                    vm.wizardProgress.completed = (step - 1);
                }
            },

            activeForm: {
                handler (activeForm) {
                    const vm = this;

                    if (activeForm.id) {
                        vm.signatureRequired = activeForm.is_signature;
                    }
                },
                deep: true
            },
        },

        computed: mapState({
            selectedForm: state => state.formModule.selectedForm,
            questions: state => state.formModule.questions,
            user: state => state.authModule.user,
        }),

        created () {
            const vm  = this;

            vm.form.initial_text = [];
            vm.form.answer       = [];

            vm.questions.map((question) => {
                vm.form.initial_text[question.id];
                vm.form.answer[question.id] = '';

                if (question.input_type_id == 2) {
                    vm.form.answer[question.id] = [];
                }
            });

            vm.wizardProgress.total = vm.questions.length;
            vm.signatureRequired = vm.selectedForm.is_signature;
        },

        methods: {
            handleNextStep () {
                const vm = this;

                if (vm.activeForm.auto_advance) {
                    vm.nextStep();
                }
            },

            nextStep () {
                const vm = this;

                const form = vm.$refs['wizardForm'];

                vm.showError = false;

                if (vm.isValidation) {
                    form.validate().then((result) => {
                        if (result.valid) {
                            if (vm.isFinished()) {
                                vm.handleSubmit();
                            } else {

                                vm.showError = false;
                                vm.questionStep += 1;
                            }
                        } else {
                            vm.showError = true;
                        }
                    });
                } else {
                    if (vm.isFinished()) {
                        vm.handleSubmit();
                    } else {
                        vm.questionStep += 1;
                    }
                }
            },

            isFinished () {
                const vm = this;
                let finished = false;

                if (
                      (vm.questionStep == vm.questions.length + 2 && (vm.selectedForm.is_signature || (!vm.selectedForm.is_signature && vm.selectedForm.opt_in))) ||
                      (vm.questionStep == vm.questions.length + 1 && !vm.selectedForm.is_signature && vm.selectedForm.opt_in) ||
                      (vm.questionStep == vm.questions.length && !vm.selectedForm.is_signature && !vm.selectedForm.opt_in)
                ) {
                    finished = true;
                }

                return finished;
            },

            prevStep () {
                const vm = this;

                vm.questionStep -= 1;
            },

            handleSubmit () {
                const vm = this;

                const form = vm.$refs['wizardForm'];

                if (vm.isValidation) {
                    form.validate().then((result) => {
                        if (result.valid) {
                            vm.$parent.formTab = 'thank page';
                        }
                    })
                }
            },
        }
    }

</script>

<style scoped>
    .dashboard_content h3{
        font-size: 22px;
        line-height: 28px;
    }
    .privacy_info {
        display: flex;
        padding: 20px 25px;
        border: 1px solid #bdeafa;
        background: rgba(149, 215, 239, 0.2);
        border-radius: 5px;
        align-items: flex-start;
        margin: 30px 0 20px 0;
    }

    .privacy_info svg {
        width: 16px;
        height: 23px;
        flex-shrink: 0;
    }

    .privacy_info p {
        font-size: 13px;
        line-height: 18px;
        margin-left: 12px;
    }

    .dashboard_content .edit_section {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
        position: relative;
        padding: 15px 30px 30px 30px;
        margin-bottom: 45px;
    }
    .tab .dashboard_content .edit_section{
        padding: 15px 25px 25px 25px;
    }
    .area_body.tab .dashboard_content .edit_section h3{
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
    }
    .cell .dashboard_content .edit_section{
        padding: 15px 20px;
        background: #fff;
    }

    /* .dashboard_content .edit_section:before {
        background: var(--editbar-color);
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        height: 5px;
        border-radius: 12px 12px 0 0;
    } */

    .signature_fld {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin: 0 0 15px 0;
    }
    .signature_fld .form_grp{
        flex: 1 1 auto;
    }
    .signature_fld .form_grp .field_wpr input{
        padding-right: 120px;
        font-family: 'Caveat', cursive;
        font-size: 20px;
        line-height: 30px; 
    }
    .signature_fld .date{
        font-family: 'Caveat', cursive;
        font-size: 18px;
        line-height: 26px;
        border: 1px solid #bdeafa;
        background: rgba(149, 215, 239, 0.2);
        border-radius: 0 5px 5px 0;
        padding: 7px 8px;
        position: absolute;
        right: -1px;
        top: -1px;
        bottom: -1px;
        width: 100px;
    }

    .cell .primary_btn {
        height: 55px;
        font-size: 17px;
        border-radius: 5px;
        letter-spacing: 1px;
    }

    .tab .primary_btn {
        height: 55px;
        font-size: 17px;
        border-radius: 5px;
    }

    .faq_list .option_list {
        padding-left: 10px;
    }

    .initialed_fld .initial_display {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px;
    }
    .initialed_fld .initial_display input{
        height: 50px;
        width: 100%;
        padding: 0 15px;
        font-family: Caveat,cursive;
        font-size: 25px;
        line-height: 30px;
        background: transparent;
    }
    .initialed_fld .initial_display .initial_wpr{
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        background: #fff;
        width: 260px;
        margin: 10px 0 15px auto;
    }
    .initialed_fld .initial_display .initial_wpr label{
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        border-right: 1px solid #e9e9e9;
        flex: 1 1 40%;
    }
    .ip_info{
        position: relative;
        margin-left: auto;
        width: 20px;
        height: 20px;
        font-size: 10px;
        color: #5a5a5a;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .initialed_fld .initial_display .initial_lable {
        width: 230px;
        padding: 6px 12px;
        border-radius: 4px;
        position: absolute;
        right: 100%;
        margin-right: 10px;
        bottom:-10px;
        display: none;
    }
    .initialed_fld .initial_display .initial_lable:after{
        content: '';
        position: absolute;
        bottom: 12px;
        right: -5px;
        width: 10px;
        height: 10px;
        background: var(--var-ip-bg-color);
        transform: rotate(45deg);
    }
    .initialed_fld .initial_display .initial_lable label{
        display: block;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        padding: 2px 0;
    }
    .initialed_fld .initial_display .initial_lable label span{
        font-weight: 400;
        color: #5a5a5a;
    }
    .initialed_fld .initial_display .ip_info:hover .initial_lable{
        display:block;
    }
    .tab .initialed_fld .initial_display .initial_wpr, .cell .initialed_fld .initial_display .initial_wpr{
        width: 100%;
    }
    /* .tab .initialed_fld .initial_display .initial_lable, .cell .initialed_fld .initial_display .initial_lable{
        width: 100%;
    } */
    .tab .initialed_fld .initial_display, .cell .initialed_fld .initial_display{
        flex-direction: column;
        align-items: stretch;
    }
    /* .tab .initialed_fld .initial_display .initial_lable label, .cell .initialed_fld .initial_display .initial_lable label{
        font-size: 11px;
        line-height: 15px;
    } */

    .question-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px 0 5px;
    }

    .question-options label {
        margin-bottom: 20px;
        cursor: pointer;
    }

    .question-oneans {
        display: flex;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 20px;
        color: #5a5a5a;
    }

    .question-oneans p, .question-options .checkbox p {
        padding-left: 12px;
    }
    .checkbox p{
        font-size: 13px;
        line-height: 18px;
    }
    .faq_list h5{
        font-size: 15px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: flex-start;
    }
    .quest_wpr{
        padding: 15px;
        position: relative;
        margin-bottom: 15px;
        border: 1px solid #edecec;
        border-radius: 6px;
    }
    .tab .quest_wpr{
        padding-left: 25px;
    }
    .quest_wpr :deep(.redactor-styles){
        padding: 0;
    }
    .quest_wpr :deep(.redactor-styles p){
        font-size: 15px !important;
        line-height: 20px;
    }
    .cell .quest_wpr :deep(.redactor-styles){
        padding: 0;
    }
    .cell .quest_wpr :deep(.redactor-styles p){
        font-size: 13px !important;
        line-height: 20px;
    }
    .quest_wpr .q_index{
        flex: 0 0 18px;
        height: 18px;
        border-radius: 50%;
        color: #fff;
        background: var(--editbar-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
        line-height: 16px;
        margin: 3px 10px 0 0;
    }
    .cell .quest_wpr .q_index{
        margin-top: 7px;
    }
    .tab .privacy_info{
        padding: 15px 20px;
    }
    .tab .dashboard_content .edit_section h3{
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
    }
    .tab .privacy_info p {
        font-size: 11px;
        line-height: 16px;
        margin-left: 10px;
    }
    .tab .faq_list h5, .tab .faq_list .redactor-styles.redactor-in :deep(p){
        font-size: 13px !important;
        line-height: 1.5 !important;
        font-weight: 500;
        margin-bottom: 0;
    }
    .tab .setting_wpr .group_item .input_label{
        font-size: 13px;
        line-height: 16px;
    }
    .tab .field_wpr input, .cell .field_wpr select{
        height: 35px;
        font-size: 13px;
        padding: 5px 10px;
    }
    .tab .question-oneans p, .tab .checkbox p{
        font-size: 12px;
    }
    .cell .faq_list h5, .cell .faq_list .redactor-styles.redactor-in :deep(p){
        font-size: 13px !important;
        line-height: 1.5 !important;
        font-weight: 500;
        margin-bottom: 0;
    }
    .cell .question-oneans p, .cell .checkbox p{
        font-size: 12px;
    }
    .cell .faq_list .sub_text{
        padding: 5px 0 0 0;
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .dashboard_content h3 {
        font-size: 14px;
        line-height: 19px;
        padding-top: 5px !important;
        margin-bottom: 10px;
    }
    .cell .legal_info p{
        font-size: 11px;
        line-height: 16px;
        color: #121525;
    }
    .cell .setting_wpr .group_item .input_label{
        font-size: 12px;
        line-height: 15px;
    }
    .cell .field_wpr input, .cell .field_wpr select{
        height: 40px;
        font-size: 13px;
        line-height: 16px;
        padding: 5px 10px;
    }
    .cell .dashboard_content .field_wpr :deep(textarea){
        height: 80px;
        font-size: 12px;
        line-height: 17px;
        padding: 10px 12px;
    }
    .cell .privacy_info {
        padding: 15px;
    }
    .cell .privacy_info i {
        font-size: 20px;
    }
    .cell .privacy_info p {
        font-size: 11px;
        line-height: 16px;
        margin-left: 12px;
    }
    span[role = alert]{
        padding: 7px 0;
        font-size: 14px;
        display: inline-block;
    }
    .tab span[role = alert]{
        font-size: 12px;
        line-height: 15px;
    }
    .cell span[role = alert]{
        font-size: 10px;
        line-height: 12px;
    }
    .cell .action_wpr .btn{
        height: 40px;
        font-size: 13px;
        padding: 0 20px;
        min-width: 100px;
    }
    .cell .dashboard_content .edit_section h3 {
        font-size: 17px!important;
        line-height: 23px!important;
        padding: 15px 0 !important;
    }
    .quest_headline{
        font-size: 17px;
        line-height: 23px;
        font-weight: 400;
        margin-bottom: 15px;
    }
    .quest_wpr .agreement :deep(p){
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
        color: #5a5a5a;
        min-height: 20px;
    }
    .quest_wpr .agreement :deep(p:empty){
        min-height: 20px;
    }
    .quest_wpr .agreement :deep(ul){
        margin-left: 20px;
    }
    .quest_wpr .agreement :deep(ul li){
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
        padding: 10px 0;
        list-style-type: disc;
    }
    .quest_wpr label.checkbox.agree_box{
        justify-content: flex-start;
        padding-top: 10px;
    }
    .quest_wpr label.checkbox.agree_box p{
        color: #121525;
    }
    .quest_wpr :deep(.upload_image){
        margin-top: 25px;
    }
    .quest_wpr :deep(.upload_image .dropzone){
        padding: 0;
        min-height: 120px;
    }
    .quest_wpr :deep(.upload_image .dropzone .dz-message){
        margin: 25px 0;
    }
    .quest_wpr :deep(.upload_image img.icon){
        max-width: 50px;
    }
    .quest_wpr :deep(.upload_image h4){
        font-size: 11px;
        line-height: 15px;
    }
    .question-options .radio_opt{
        display: flex;
        align-items: flex-start;
        margin: 0;
        cursor: pointer;
    }
    .radio_box{
        width: 13px;
        height: 13px;
        flex-shrink: 0;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 3px;
    }
    .radio_box .dot{
        position: absolute;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .radio_box.active .dot{
        transform: scale(1);
    }
    .info{
        font-size: 11px;
        line-height: 15px;
    }
    .dashboard_content h3.title{
        margin-bottom: 15px;
        font-size: 15px;
        line-height:20px;
    }
</style>
